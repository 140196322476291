import { SortDirection } from '@angular/material/sort';
import { PageSizeOptions } from '@sealights/sl-query-builder';

export interface TableFilterState {
	search?: string | null;
	page?: number;
	pageSize?: number;
	sortBy?: string;
	sortDirection?: SortDirection;
}

export const defaultTableFilterState: TableFilterState = {
	search: null,
	page: 1,
	pageSize: 20,
	sortBy: '',
	sortDirection: 'asc'
};

export const DEFAULT_PAGE_SIZE_OPTIONS: PageSizeOptions[] = [10, 15, 20, 25, 30, 40, 50];
